import React, { useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import html2pdf from "html2pdf.js";
// Sample receipt data from API
// const receiptData = {
//   vendor_logo:
//     "https://vibes-up.s3.amazonaws.com/logo/b3rsvADB6KlGTEe08pbirnJ1AtvkSRgpOH6FCQtS.jpg",
//   bill_value: "12250.00",
//   reservation_id: 71,
//   time: "10:45am",
//   date: "Sep 21",
//   max_guest_size: "0",
//   menu_item: [
//     {
//       id: 90,
//       link_reservation_id: 71,
//       menu_id: 2,
//       quantity: 1,
//       amount: "2500.00",
//     },
//     {
//       id: 91,
//       link_reservation_id: 71,
//       menu_id: 3,
//       quantity: 1,
//       amount: "7500.00",
//     },
//   ],
//   house_rules: [
//     {
//       id: 3,
//       vendor_id: 2,
//       rule_title: "Dress Code",
//       rule_description: "Decently",
//     },
//   ],
//   contact_name: "Alimi Samuel Ayomide",
//   contact_person_email: "alimisamuel121@gmail.com",
//   contact_person_phoneNumber: "09076772400",
// };

const ReceiptComp = React.forwardRef((props, ref) => {
  const { receipt, id } = props;

  return (
    <div
      id={id}
      style={{ maxWidth: "600px", margin: "auto", fontFamily: "Arial" }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={receipt?.vendor_logo}
          alt="Vendor Logo"
          style={{ width: "80px" }}
        />
        <h2 style={{ fontFamily: "outfit" }}>Reservation Order Receipt</h2>
      </div>
      <Box
        sx={{
          display: "flex",
          margin: "0 auto",
          width: "85%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: 700, fontSize: "12px" }}>
            Bill Value
          </Typography>
          <Typography sx={{ color: "#A71200", fontWeight: 700 }}>
            N{parseFloat(receipt?.bill_value).toLocaleString()}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 700, fontSize: "12px" }}>
            Reservation ID
          </Typography>
          <Typography sx={{ fontWeight: 700 }}>
            {receipt?.reservation_id}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ borderTop: "4px solid #dedede", my: 3 }} />
      <Box>
        <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
          Menu Details
        </Typography>
        <Box sx={{ mt: 2 }}>
          {receipt?.menu_item?.map((menu) => (
            <Box sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                {menu?.name} -{menu?.quantity} Packs
              </Typography>
              <Typography
                sx={{ fontWeight: 300, fontSize: "12px", color: "#5b5b5b" }}
              >
                N{parseFloat(menu?.price).toLocaleString()}
              </Typography>
            </Box>
          ))}
        </Box>
        {/* <strong>Date:</strong> {receipt.date} <br />
        <strong>Time:</strong> {receipt.time} <br />
        <strong>Reservation ID:</strong> {receipt.reservation_id} */}
      </Box>
      <Box sx={{ borderTop: "4px solid #d7d7d7", my: 3 }} />
      <Box>
        <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
          Timing Details
        </Typography>
        <Typography sx={{ mt: 1, color: "#5b5b5b" }}>
          This table is being reserved for{" "}
          <b style={{ color: "#000" }}>{receipt?.time}</b> on{" "}
          <b style={{ color: "#000" }}>{receipt?.date}</b>{" "}
        </Typography>
      </Box>
      <Box sx={{ borderTop: "4px solid #dedede", my: 3 }} />
      <Box>
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
          Contact Details
        </Typography>
        <Box sx={{ width: "80%", mt: 3 }}>
          <InputLabel
            sx={{
              color: "#151515",
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            Full Name
          </InputLabel>
          <TextField
            margin="dense"
            value={receipt?.contact_name}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "10px",
                backgroundColor: "#f4f4f4",
                color: "#151515",
                border: "1px solid #151515",
                fontWeight: 500,
              },
              readOnly: true,
            }}
          />
          <InputLabel
            sx={{ color: "#151515", fontSize: "12px", fontWeight: 500, mt: 2 }}
          >
            Phone Number
          </InputLabel>
          <TextField
            margin="dense"
            value={receipt?.contact_person_phoneNumber}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "10px",
                backgroundColor: "#f4f4f4",
                color: "#151515",
                border: "1px solid #151515",
                fontWeight: 500,
              },
              readOnly: true,
            }}
          />
          <InputLabel
            sx={{ color: "#151515", fontSize: "12px", fontWeight: 500, mt: 2 }}
          >
            Email Address
          </InputLabel>
          <TextField
            margin="dense"
            value={receipt?.contact_person_email}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "10px",
                backgroundColor: "#f4f4f4",
                color: "#151515",
                border: "1px solid #151515",
                fontWeight: 500,
              },
              readOnly: true,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ borderTop: "4px solid #d7d7d7", my: 3 }} />
      <Box>
        <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
          Guest Size
        </Typography>
        <Typography sx={{ mt: 1, color: "#5b5b5b" }}>
          You are reserving for{" "}
          <b style={{ color: "#000" }}>{receipt?.max_guest_size} Guests</b>
        </Typography>
      </Box>
      <Box sx={{ borderTop: "4px solid #d7d7d7", my: 3 }} />
      <Box>
        {receipt?.house_rules?.map((rules) => (
          <>
            <Typography sx={{ fontWeight: 700, fontSize: "14px", mb: 1 }}>
              {rules?.rule_title}
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: 700, fontSize: "12px" }}>
              {rules?.rule_description}
            </Typography>
          </>
        ))}
      </Box>
    </div>
  );
});

const Receipt = ({ receiptData }) => {
  const receiptRef = useRef();

  const [show, setShow] = useState(false);

  const handleDownloadPdf = () => {
    setShow(true);

    const element = document.querySelector("#invoice");
    html2pdf(element);
    setShow(false);
  };

  // Print the receipt component
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(receiptRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };

  return (
    <>
      <div style={{ padding: "20px" , display:'none'}}>
<ReceiptComp ref={receiptRef} receipt={receiptData} id="invoice" />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Button
       onClick={handleDownloadPdf}
          variant="contained"
          sx={{
            width: "200px",
            height: "63px",
            bgcolor: "#151515",
            borderRadius: "10px",
            fontWeight: 700,
          }}
        >
          Download Receipt
        </Button>
      </div>
    </>
  );
};

// Basic button styling

export default Receipt;
